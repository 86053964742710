import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "50",
  viewBox: "0 0 50 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#212529",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M20.3814 14.7009C12.2781 14.7009 5.70907 21.2699 5.70907 29.3732C5.70907 37.4765 12.2781 44.0455 20.3814 44.0455C28.4846 44.0455 35.0537 37.4765 35.0537 29.3732C35.0537 21.2699 28.4847 14.7009 20.3814 14.7009ZM0 29.3732C0 18.1169 9.12505 8.99182 20.3814 8.99182C31.6377 8.99182 40.7627 18.1169 40.7627 29.3732C40.7627 40.6295 31.6377 49.7546 20.3814 49.7546C9.12504 49.7546 0 40.6295 0 29.3732Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#212529",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M20.3814 23.3444C17.0518 23.3444 14.3526 26.0436 14.3526 29.3732C14.3526 32.7028 17.0518 35.402 20.3814 35.402C23.711 35.402 26.4101 32.7028 26.4101 29.3732C26.4101 26.0436 23.711 23.3444 20.3814 23.3444ZM8.64353 29.3732C8.64353 22.8906 13.8987 17.6354 20.3814 17.6354C26.864 17.6354 32.1192 22.8906 32.1192 29.3732C32.1192 35.8558 26.864 41.1111 20.3814 41.1111C13.8987 41.1111 8.64353 35.8558 8.64353 29.3732Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FF7602",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M40.8646 9.38155C41.4502 9.96751 41.4499 10.9173 40.864 11.5029L21.6929 30.6625C21.107 31.2481 20.1572 31.2478 19.5716 30.6619C18.986 30.0759 18.9863 29.1262 19.5722 28.5405L38.7433 9.38092C39.3292 8.79531 40.279 8.79559 40.8646 9.38155Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FF7602",
      d: "M42.5211 14.4953L35.0879 15.1461L35.7502 7.72435L43.2291 0.245483L42.5782 7.66727L50 7.01644L42.5211 14.4953Z"
    }, null, -1)
  ])))
}
export default { render: render }